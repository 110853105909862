function addEventsToMylodFooter() {
  window.addEventListener("load", () => {
    let myLodLink = document.getElementById("myLodFooterLink");
    if (myLodLink) {
      myLodLink.addEventListener("click", function () {
        document
          .getElementById("join-dropdown-container")
          .classList.remove("dropdown-hidden");
        document
          .getElementById("background-overlay")
          .classList.remove("overlay-hidden");
      });
    }
  });
}

addEventsToMylodFooter();
